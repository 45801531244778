* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.activateSidebar {
  display: block !important;
  transition: "0.5s";
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
} */
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #152e88; /* Light grey */
  border-top: 10px solid white; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100000;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white; 
  border-radius: 10px; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #152E88; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0930cc; 
}
